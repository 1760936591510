import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: "/dev",
        name: "dev",
        meta: { title: "开发" },
        redirect: "/dev/lists",
        component: Main,
        children: [
            {
                path: "/dev/lists",
                name: "dev_lists",
                meta: {
                    title: "代码生成器",
                    icon: "icon_fenxiao_dev",
                    parentPath: "/dev",
                    permission: ["view"],
                },
                component: () => import("@/views/dev/code/lists.vue"),
            },
            {
                path: "/dev/edit",
                name: "dev_edit",
                meta: {
                    hidden: true,
                    title: "续费订单",
                    parentPath: "/dev",
                    prevPath: "/dev/edit"
                },
                component: () => import("@/views/dev/code/edit.vue"),
            },
        ],
    },
];
export default routes;
