import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: "/community",
        name: "community",
        meta: { title: "社区" },
        redirect: "/community/user/lists",
        component: Main,
        children: [
            {
                path: "/community/user/lists",
                name: "community_user",
                meta: {
                    title: "用户信息",
                    icon: "icon_fenxiao_dev",
                    parentPath: "/community",
                    permission: ["view"],
                },
                component: () => import("@/views/community/community_user/index.vue"),
            },
            {
                path: "/community/user/edit",
                name: "community_user_edit",
                meta: {
                    hidden: true,
                    title: "用户信息编辑",
                    parentPath: "/community",
                    prevPath: "/community/user/lists"
                },
                component: () => import("@/views/community/community_user/edit.vue"),
            },
            {
                path: "/community/community_topic/lists",
                name: "community_user",
                meta: {
                    title: "话题",
                    icon: "icon_fenxiao_dev",
                    parentPath: "/community",
                    permission: ["view"],
                },
                component: () => import("@/views/community/community_topic/index.vue"),
            },
            {
                path: "/community/community_topic/edit",
                name: "community_user_edit",
                meta: {
                    hidden: true,
                    title: "话题编辑",
                    parentPath: "/community",
                    prevPath: "/community/community_topic/lists"
                },
                component: () => import("@/views/community/community_topic/edit.vue"),
            },

            {
                path: "/community/community_search_record/lists",
                name: "community_user",
                meta: {
                    title: "搜索记录",
                    icon: "icon_fenxiao_dev",
                    parentPath: "/community",
                    permission: ["view"],
                },
                component: () => import("@/views/community/community_search_record/index.vue"),
            },
            {
                path: "/community/community_search_record/edit",
                name: "community_user_edit",
                meta: {
                    hidden: true,
                    title: "搜索记录编辑",
                    parentPath: "/community",
                    prevPath: "/community/community_search_record/lists"
                },
                component: () => import("@/views/community/community_search_record/edit.vue"),
            },

            // 
            {
                path: "/community/community_follow/lists",
                name: "community_user",
                meta: {
                    title: "关注",
                    icon: "icon_fenxiao_dev",
                    parentPath: "/community",
                    permission: ["view"],
                },
                component: () => import("@/views/community/community_follow/index.vue"),
            },
            {
                path: "/community/community_follow/edit",
                name: "community_user_edit",
                meta: {
                    hidden: true,
                    title: "关注编辑",
                    parentPath: "/community",
                    prevPath: "/community/community_follow/lists"
                },
                component: () => import("@/views/community/community_follow/edit.vue"),
            },
            // 
            {
                path: "/community/community_like/lists",
                name: "community_user",
                meta: {
                    title: "点赞",
                    icon: "icon_fenxiao_dev",
                    parentPath: "/community",
                    permission: ["view"],
                },
                component: () => import("@/views/community/community_like/index.vue"),
            },
            {
                path: "/community/community_like/edit",
                name: "community_user_edit",
                meta: {
                    hidden: true,
                    title: "点赞编辑",
                    parentPath: "/community",
                    prevPath: "/community/community_like/lists"
                },
                component: () => import("@/views/community/community_like/edit.vue"),
            },
            // 
            {
                path: "/community/community_comment/lists",
                name: "community_user",
                meta: {
                    title: "评论",
                    icon: "icon_fenxiao_dev",
                    parentPath: "/community",
                    permission: ["view"],
                },
                component: () => import("@/views/community/community_comment/index.vue"),
            },
            {
                path: "/community/community_comment/edit",
                name: "community_user_edit",
                meta: {
                    hidden: true,
                    title: "评论编辑",
                    parentPath: "/community",
                    prevPath: "/community/community_comment/lists"
                },
                component: () => import("@/views/community/community_comment/edit.vue"),
            },
            // 

            {
                path: "/community/community_category/lists",
                name: "community_user",
                meta: {
                    title: "分类",
                    icon: "icon_fenxiao_dev",
                    parentPath: "/community",
                    permission: ["view"],
                },
                component: () => import("@/views/community/community_category/index.vue"),
            },
            {
                path: "/community/community_category/edit",
                name: "community_user_edit",
                meta: {
                    hidden: true,
                    title: "分类编辑",
                    parentPath: "/community",
                    prevPath: "/community/community_category/lists"
                },
                component: () => import("@/views/community/community_category/edit.vue"),
            },
            // 
            {
                path: "/community/community_article/lists",
                name: "community_user",
                meta: {
                    title: "文章",
                    icon: "icon_fenxiao_dev",
                    parentPath: "/community",
                    permission: ["view"],
                },
                component: () => import("@/views/community/community_article/index.vue"),
            },
            {
                path: "/community/community_article/edit",
                name: "community_user_edit",
                meta: {
                    hidden: true,
                    title: "文章编辑",
                    parentPath: "/community",
                    prevPath: "/community/community_article/lists"
                },
                component: () => import("@/views/community/community_article/edit.vue"),
            },
            // 
            {
                path: "/community/community_article_image/lists",
                name: "community_user",
                meta: {
                    title: "图片",
                    icon: "icon_fenxiao_dev",
                    parentPath: "/community",
                    permission: ["view"],
                },
                component: () => import("@/views/community/community_article_image/index.vue"),
            },
            {
                path: "/community/community_article_image/edit",
                name: "community_user_edit",
                meta: {
                    hidden: true,
                    title: "图片编辑",
                    parentPath: "/community",
                    prevPath: "/community/community_article_image/lists"
                },
                component: () => import("@/views/community/community_article_image/edit.vue"),
            },


        ],
    },
];
export default routes;
