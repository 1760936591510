import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: '/article',
        name: 'article',
        meta: { title: '资讯' },
        redirect: '/article/lists',
        component: Main,
        children: [
            {
                path: '/article/lists',
                name: 'article',
                meta: {
                    title: '资讯管理',
                    parentPath: '/article',
                    icon: 'icon_notice',
                    permission: ['view']
                },
                component: () => import('@/views/article/lists.vue')
            },
            {
                path: '/article/article_edit',
                name: 'article_edit',
                meta: {
                    hidden: true,
                    title: '资讯管理',
                    parentPath: '/article',
                    prevPath: '/article/lists',
                },
                component: () => import('@/views/article/lists_edit.vue')
            },
            {
                path: '/article/category_lists',
                name: 'category_lists',
                meta: {
                    title: '资讯分类',
                    parentPath: '/article',
                    icon: 'icon_notice',
                    permission: ['view']
                },
                component: () => import('@/views/article/category.vue')
            }
        ]
    },
];
export default routes;
